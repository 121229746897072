// Don't change manually as this is generated automatically!

export const it_IT = {
    '#Dictionary-eMobilitaets-Oekosystem#': "Ecosistema della mobilità elettrica",
    '#HL-eMobilitaetsOekosystem-DB#': "Ecosistema della mobilità elettrica di Daimler Buses",
    '#SHL-eSystem-Klick-Explainer#': "Design del sistema",
    '#SL-SystemDesign-eSystem-Klick-Explainer#': "Grazie a studi di fattibilità supportati da software, rendiamo il trasporto pubblico urbano elettrico pianificabile in modo affidabile e realizziamo la progettazione del sistema per il vostro deposito.",
    '#AB1-SystemDesign-eSystem-Klick-Explainer#': "In uno studio di fattibilità, i consulenti di elettromobilità di Daimler Buses verificano il fabbisogno energetico di tutte le linee e i percorsi esistenti in un modello di simulazione tarato specificamente per l’eCitaro. Il livello di carica viene rilevato nel corso della giornata e vengono suggerite eventuali pause di ricarica necessarie. Le linee saranno ottimizzate per gli autobus elettrici.",
    '#AB2-SystemDesign-eSystem-Klick-Explainer#': "Il design del sistema comprende il layout del deposito nel suo complesso e l'infrastruttura di ricarica. Ciò include il calcolo della potenza elettrica o della quantità di energia necessaria. Inoltre, vengono realizzati i concetti di ricarica e la potenza di ricarica viene ottimizzata tramite la gestione del deposito.",
    '#ZHL-Vorteile-eSysem-Klick-Explainer#': "I vostri vantaggi",
    '#BP1-SystemDesign-eSystem-Klick-Explainer#': "Trasparenza sulla fattibilità delle corse giornaliere delle linee di autobus esistenti con l'eCitaro",
    '#BP2-SystemDesign-eSystem-Klick-Explainer#': "Supporto per l'ottimizzazione del ricircolo",
    '#BP3-SystemDesign-eSystem-Klick-Explainer#': "Guida alla scelta della configurazione ottimale della batteria e del concetto di ricarica",
    '#BP4-SystemDesign-eSystem-Klick-Explainer#': "Concetto globale per il passaggio del deposito\nalla gestione di autobus elettrici",
    '#ZHL1-eSystem-Klick-Explainer#': "Accumulatore a batteria",
    '#SL1-eSystem-Klick-Explainer#': "Insieme a Mercedes-Benz Energy, diamo una seconda vita alle batterie usate degli autobus.",
    '#AB1-eSystem-Klick-Explainer#': "Con un accumulatore di energia stazionario composto da batterie per autobus elettrici usate è possibile prolungare notevolmente il loro ciclo di vita e aumentare ulteriormente la sostenibilità della propria flotta di autobus elettrici. In combinazione con un sistema intelligente di ricarica e gestione dell’energia è inoltre possibile ridurre i picchi di potenza e di conseguenza i costi dell’elettricità. Un ulteriore potenziale di ottimizzazione si ottiene in combinazione con un impianto fotovoltaico che produce durante il giorno l’energia rigenerativa che può poi essere utilizzata di notte per ricaricare gli autobus.",
    '#BP1-eSystem-Klick-Explainer#': "Aumento della redditività",
    '#BP2-eSystem-Klick-Explainer#': "Numero ridotto di picchi di carico",
    '#BP3-eSystem-Klick-Explainer#': "Riduzione dell'impatto ambientale",
    '#ZHL2-eSystem-Klick-Explainer#': "Trasformatore",
    '#SL2-eSystem-Klick-Explainer#': "Vi offriamo consulenza per la scelta del trasformatore più adatto al vostro deposito.",
    '#BE1_eSystem-Klick-Explainer#': "Deposito dei clienti",
    '#AB2-eSystem-Klick-Explainer#': "Per poter erogare energia a una flotta di autobus alimentati a batteria, l'energia proveniente dalla rete di media tensione (generalmente 6 kV - 60 kV) deve essere ridotta a un livello di tensione di 400 V CA. A seconda delle esigenze e delle condizioni di spazio nel deposito del veicolo, ciò può avvenire con un trasformatore compatto o, nel caso di flotte di grandi dimensioni, con una stazione di trasformazione accessibile.",
    '#BP1-Transformator-eSystem-Klick-Explainer#': "Messa a disposizione della potenza complessiva necessaria in base alle dimensioni della flotta di autobus e al concetto di ricarica",
    '#BP2-Transformator-eSystem-Klick-Explainer#': "Pianificazione sicura attraverso il coordinamento della fornitura di energia con le fasi di ampliamento pianificate della vostra flotta di autobus",
    '#ZHL3-eSystem-Klick-Explainer#': "Lavori di costruzione e impianti elettrici",
    '#SL3-eSystem-Klick-Explainer#': "Garantiamo un’alimentazione di energia sicura ed efficiente nel tuo deposito, dalla pianificazione all’esecuzione.",
    '#AB3-eSystem-Klick-Explainer#': "I consulenti di elettromobilità di Daimler Buses si occupano della progettazione di tutti i lavori di costruzione necessari per alimentare i caricabatteria. Il coordinamento e l'esecuzione sono affidati a una direzione centrale del progetto.",
    '#BP1-Bauarbeiten-eSystem-Klick-Explainer#': "L'attuazione viene realizzata tenendo conto dell'obiettivo generale di ampliamento del deposito aziendale",
    '#BP2-Bauarbeiten-eSystem-Klick-Explainer#': "Considerazione dei singoli processi aziendali nella progettazione edilizia",
    '#BP3-Bauarbeiten-eSystem-Klick-Explainer#': "I lavori edili possono essere eseguiti durante il funzionamento",
    '#ZHL4-eSystem-Klick-Explainer#': "Gestione della ricarica e del deposito",
    '#SL4-eSystem-Klick-Explainer#': "Con le nostre soluzioni di gestione della ricarica e del deposito è possibile tenere sempre sotto controllo tutte le ricariche e ridurre notevolmente i costi dell’elettricità.",
    '#AB4-eSystem-Klick-Explainer#': "Una gestione professionale della ricarica consente di ottimizzare la potenza di ricarica dell’intera flotta, in modo che gli autobus vengano caricati e precondizionati per tempo a fronte di una potenza totale controllata. In questo modo è possibile ridurre costosi picchi di carico della rete e l’acquisto di elettricità può avvenire nella fascia di prezzo più vantaggiosa. I piani di ricarica vengono creati automaticamente all’interno delle finestre di ricarica predefinite e anche il precondizionamento dei veicoli viene gestito centralmente per garantire la massima autonomia degli autobus. A tale scopo offriamo soluzioni chiare di gestione della ricarica per le piccole flotte, ma siamo anche in grado di gestire in modo ottimale le grandi flotte di autobus elettrici con soluzioni integrate di gestione del deposito, e di assegnare percorsi e aree di parcheggio in modo mirato.",
    '#BP1-Lademanagement-eSystem-Klick-Explainer#': "Riduzione dei costi grazie a picchi di carico ridotti",
    '#BP2-Lademanagement-eSystem-Klick-Explainer#': "Piani di ricarica automatici per tutti gli autobus in funzione degli orari di partenza e dei percorsi",
    '#BP3-Lademanagement-eSystem-Klick-Explainer#': "Comando centralizzato del precondizionamento per la massima autonomia e un maggiore comfort",
    '#BP4-Lademanagement-eSystem-Klick-Explainer#': "Ricarica compatibile con la rete nella migliore fascia di prezzo",
    '#ZHL5-eSystem-Klick-Explainer#': "infrastruttura di ricarica",
    '#SL5-eSystem-Klick-Explainer#': "Vi supportiamo con soluzioni di infrastrutture di ricarica su misura da noti produttori europei di sistemi di ricarica.",
    '#AB5-eSystem-Klick-Explainer#': "Il concetto di funzionamento ottimale per un autobus a trazione elettrica dipende in larga misura dalle rispettive condizioni di impiego. I consulenti di elettromobilità di Daimler Buses Solutions determineranno quale infrastruttura di ricarica è più adatta in base alle condizioni locali: ricarica plug-in presso il deposito o una combinazione di ricarica plug-in e pantografi lungo il percorso. Inoltre, sulla base del profilo di impiego, vengono forniti consigli sulla tecnica di ricarica più adatta.",
    '#BP1-Ladeinfrastruktur-eSystem-Klick-Explainer#': "Infrastruttura di ricarica e veicolo da un unico fornitore",
    '#BP2-Ladeinfrastruktur-eSystem-Klick-Explainer#': "Perfetta interazione tra gestione della ricarica, infrastruttura e veicolo",
    '#BP3-Ladeinfrastruktur-eSystem-Klick-Explainer#': "Compatibilità garantita con i sistemi di batterie dell'eCitaro - grazie a numerosi test di interoperabilità",
    '#ZHL6-eSystem-Klick-Explainer#': "Parti di ricambio, contratti eService e rete di assistenza",
    '#SL6-eSystem-Klick-Explainer#': "Con i contratti di assistenza OMNIplus eService per autobus elettrici vi offriamo un'assistenza professionale a costi prevedibili.",
    '#AB6-eSystem-Klick-Explainer#': "Affidati alla nostra collaudata offerta di contratti di assistenza OMNIplus eService che abbiamo adattato alle esigenze della mobilità elettrica. Potrai configurare la gamma di servizi ottimale per la tua flotta di autobus elettrici in base alle tue esigenze. Con il contratto eService ePremium, ad esempio, sono coperte tutte le manutenzioni nel rispetto delle indicazioni del produttore e tutte le riparazioni della rete di bordo ad alto voltaggio. Il pacchetto comprende anche altre riparazioni dell’intero veicolo, inclusi tutti i ricambi necessari. Per l’approvvigionamento professionale di ricambi originali è disponibile la più vasta e capillare rete di assistenza per autobus in Europa, con oltre 600 punti di assistenza autorizzati. In questo modo è possibile viaggiare sempre in modo sicuro, affidabile e redditizio con il proprio eCitaro a trazione completamente elettrica.",
    '#BP1-Ersatzteile-eSystem-Klick-Explainer#': "Due contratti OMNIplus eService a scelta: eBasic o ePremium",
    '#BP2-Ersatzteile-eSystem-Klick-Explainer#': "Una variante per la batteria ad alto voltaggio: garanzia estesa sulla batteria",
    '#BP3-Ersatzteile-eSystem-Klick-Explainer#': "Offerta di servizi a prova di futuro per la mobilità elettrica",
    '#BP4-Ersatzteile-eSystem-Klick-Explainer#': "Prezzi del pacchetto interessanti anziché servizi singoli",
    '#BP5-Ersatzteile-eSystem-Klick-Explainer#': "Contratti di assistenza e manutenzione anche per l'infrastruttura di ricarica",
    '#ZHL7-eSystem-Klick-Explainer#': "Servizi digitali",
    '#SL7-eSystem-Klick-Explainer#': "Vi offriamo il mondo dei servizi digitali OMNIplus ON per il vostro Mercedes-Benz eCitaro e eCitaro fuel cell.",
    '#AB7-eSystem-Klick-Explainer#': "Inoltre, OMNIplus ON offre una vasta gamma di servizi digitali in tempo reale garantendo la massima redditività e disponibilità dei veicoli. Ne costituisce un esempio il nuovo OMNIplus ON Uptime pro con diagnosi remota. In questo modo lo stato dei veicoli è sempre sotto controllo ed è possibile ottimizzare la pianificazione della loro manutenzione. OMNIplus ON monitor offre addirittura la possibilità di monitorare il livello di carica e l’autonomia residua dei propri veicoli, nonché di analizzarne il consumo di energia, incluso ad esempio quello del nuovo Mercedes-Benz eCitaro fuel cell. Inoltre, le interfacce virtuali di OMNIplus ON consentono una facile integrazione dei dati nel proprio sistema di gestione delle flotte o del deposito. Il nuovo Data Package TiGR, certificato secondo lo standard ITxPT unitario, fornisce una base di dati omogenea per l’intera flotta, semplificando così le analisi dei veicoli. A questo proposito: tutti i dati del veicolo vengono messi a disposizione in tempo reale e, su richiesta, anche in formato grezzo. Ciò avviene sempre in modo sicuro nel rispetto del nuovo regolamento UN 155 sulla sicurezza informatica dei veicoli.",
    '#BP1-DigitaleServices-eSystem-Klick-Explainer#': "Massima disponibilità dei veicoli",
    '#BP2-DigitaleServices-eSystem-Klick-Explainer#': "Trasparenza sull'autonomia attuale e sui consumi dei veicoli eCitaro",
    '#BP3-DigitaleServices-eSystem-Klick-Explainer#': "Massima sicurezza dei dati",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff#': "Infrastruttura per idrogeno",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_copy_head#': "Supportiamo i clienti con\nsoluzioni infrastrutturali su misura per garantire un approvvigionamento efficiente di idrogeno.",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_copy#': "Il concetto di funzionamento ottimale di un autobus a trazione alternativa dipende in larga misura dalle condizioni di impiego. Al fine di prolungare l’autonomia dei nostri autobus Mercedes-Benz eCitaro fuel cell, questi dispongono, oltre che di batterie, anche di una cella a combustibile alimentata a idrogeno (H2). Gli esperti di elettromobilità di Daimler Buses determinano l’infrastruttura di rifornimento più adatta tenendo conto della quantità di idrogeno necessaria e delle condizioni locali. Valutano se è possibile utilizzare un distributore di idrogeno nei dintorni o se è opportuno allestirne uno nel proprio deposito predisponendo una stazione di rifornimento. Inoltre, verificano se l’alimentazione di idrogeno può essere realizzata con una soluzione trailer o un elettrolizzatore e forniscono anche consulenza e offerte su misura per l’alimentatore di corrente adatto per la propria flotta di autobus elettrici.",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile#': "Vantaggi:",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b1#': "Infrastruttura e veicolo da un’unica fonte",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b2#': "Perfetta interazione tra gestione della ricarica, infrastruttura e veicolo",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b3#': "Supporto in fase decisionale per la scelta del sistema di rifornimento di H2 ottimale",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b4#': "Collaborazione con partner esperti",
} as const;
