import styled, {keyframes} from 'styled-components';
import {DimensionProps} from '../customTypes';
import {GRID_WIDTH, GRID_HEIGHT} from '../../../config';
import Line from '../../content/separator.svg';

const slideInAnimation = () => keyframes`
  0% {
    transform: translate(300%, -50%);
  }

  100% {
    transform: translate(100%, -50%);
  }
`;

const fadeInAnimation = () => keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const frameAnimation = () => keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%,-50%) scale(.7);
  }

  100% {
    opacity: 1;
    transform: translate(-50%,-50%) scale(1);
  }
`;

const iconAnimation = () => keyframes`
  0% {
    transform: scale(.5);
  }

  90% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
`;

const zoomAnimation = () => keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }
  
  100% {
    transform: scale(1);
  }
`;

export const HexagonImagesWrapper = styled.div<DimensionProps>`
    width: ${DimensionProps => DimensionProps.width}vW;
    position: absolute;
    top: 50%;
    right: 39%;
    transform: translate(0%, -50%);
    animation: ${slideInAnimation} .4s cubic-bezier(.47,1.64,.41,.8) forwards;
    
    svg {
        image {
            opacity: 0;
            transition: opacity .5s ease-in-out;
            
            &.active {
                opacity: 1;
            }
        }
        
        .main-images {
            image {
                animation: ${zoomAnimation} 10s ease-in-out 1s infinite;
            }
        }
    }
`;

export const HexagonTextWrapper = styled.div<DimensionProps>`
    width: ${DimensionProps => DimensionProps.width}vW;
    margin: 17.5% auto;
    position: relative;
    opacity: 0;
    animation ${fadeInAnimation} .5s ease-in-out forwards;
`;

export const HexagonBackgroundWrapper = styled.div<DimensionProps>`
    width: ${DimensionProps => DimensionProps.width}vW;
    position: absolute;
    left: 39%;
    top: 50%;
    margin-top: ${-50/GRID_HEIGHT * 100}vH;
    transform: translate(-50%,-50%);
    height: 100%;
    animation: ${fadeInAnimation} 1s ease-in-out;
    
    img[class^='background-'] {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }

    .background-full {
        width: 90%;
    }
    
    .background-frame {
        opacity: 0;
        transform: translate(-50%,-50%) scale(.7);
        animation: ${frameAnimation} .5s cubic-bezier(.47,1.64,.41,.8) forwards;
        animation-delay: .5s;
    }
`;

export const ContentIcon = styled.div<DimensionProps>`
    width: ${DimensionProps => DimensionProps.width}vW;
    position: absolute;
    z-index: 10;
    position: absolute;
    left: -3.75%;
    top: 11.5%;
    animation: ${iconAnimation} .5s cubic-bezier(.47,1.64,.41,.8) .5s;
    
    svg {
        #icon {
            animation ${fadeInAnimation} .5s ease-in-out forwards;
        }
    }
`;

export const ContentHeadline = styled.h2`
    color: #333;
    font-weight: 400;
    font-size: 2vW;
    padding-bottom: .5em;
    margin-bottom: .5em;
    position: relative;
    
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: ${3 / GRID_WIDTH * 100}vW;
        background: url(${Line}) no-repeat;
        background-size: 100% auto;
        position: absolute;
        left: 0;
        bottom: 0;
    }
`;

export const ContentIntro = styled.div`
    color: #666;
    font-size: 1vW;
    margin: 0 0 1em;
    font-weight: 600;
`;

export const ContentText = styled.div`
    color: #666;
    font-size: 1vW;
    position: relative;
    padding-bottom: 1.5vW;
    margin-bottom: 2vW;
    
    p {
        margin: 0;
        line-height: 1.2;
        
        &:not(&:last-child) {
            margin: 0 0 1em;
        }
    }
    
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: ${3 / GRID_WIDTH * 100}vW;
        background: url(${Line}) no-repeat;
        background-size: 100% auto;
        position: absolute;
        left: 0;
        bottom: 0;
    }
`;