import {Locale, Pages} from '../../assets/ts/customTypes';
import {MainContainer} from '../../assets/ts/layout/main';
import {ContentContainer} from '../../assets/ts/layout/content';
import {LandingpageClickable} from '../components/LandingpageClickable';
import {OverviewButton} from '../components/OverviewButton';
// import {Button} from '../components/Button';
import {ConnectionLines} from '../components/ConnectionLines';


export function Landingpage({
    locale,
    updateCurrentPage
}: {
    locale: Locale
    updateCurrentPage: (page: Pages) => void
}) {
    const {button} = locale[Pages.LANDINGPAGE];
    return (
        <MainContainer name='landingpage' background={locale[Pages.LANDINGPAGE].background}>
            <OverviewButton page={Pages.SYSTEMDESIGN} button={button} updateCurrentPage={updateCurrentPage} />
            <ContentContainer>
                <ConnectionLines/>
                <LandingpageClickable
                    page={Pages.BATTERY}
                    offsetLeft={6}
                    offsetTop={29.5}
                    width={134}
                    position={2}
                    updateCurrentPage={updateCurrentPage}/>
                <LandingpageClickable
                    page={Pages.CHARGING}
                    offsetLeft={37.2}
                    offsetTop={65}
                    width={133}
                    position={7}
                    updateCurrentPage={updateCurrentPage}/>
                <LandingpageClickable
                    page={Pages.CONSTRUCTION}
                    offsetLeft={6}
                    offsetTop={63}
                    width={119}
                    position={5}
                    updateCurrentPage={updateCurrentPage}/>
                <LandingpageClickable
                    page={Pages.DIGITALSERVICE}
                    offsetLeft={78.7}
                    offsetTop={58}
                    width={206}
                    position={6}
                    updateCurrentPage={updateCurrentPage}/>
                <LandingpageClickable
                    page={Pages.SPAREPARTS}
                    offsetLeft={56.4}
                    offsetTop={26.5}
                    width={134}
                    position={1}
                    updateCurrentPage={updateCurrentPage}/>
                <LandingpageClickable
                    page={Pages.MANAGEMENT}
                    offsetLeft={40.5}
                    offsetTop={36}
                    width={166}
                    position={4}
                    updateCurrentPage={updateCurrentPage}/>
                <LandingpageClickable
                    page={Pages.TRANSFORMER}
                    offsetLeft={28}
                    offsetTop={30}
                    width={134}
                    position={3}
                    updateCurrentPage={updateCurrentPage}/>
                <LandingpageClickable
                    page={Pages.HYDROGEN}
                    offsetLeft={21.6}
                    offsetTop={40}
                    width={134}
                    position={3}
                    updateCurrentPage={updateCurrentPage}/>
            </ContentContainer>
            {/* <Button page={Pages.FEEDBACKPAGE} text={feedback} position={'right'} icon={'right'} updateCurrentPage={updateCurrentPage} /> */}
        </MainContainer>
    );
}