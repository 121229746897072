// Don't change manually as this is generated automatically!

export const en_GB = {
    '#Dictionary-eMobilitaets-Oekosystem#': "eMobility Ecosystem",
    '#HL-eMobilitaetsOekosystem-DB#': "The Daimler Buses eMobility Ecosystem",
    '#SHL-eSystem-Klick-Explainer#': "system design",
    '#SL-SystemDesign-eSystem-Klick-Explainer#': "Using our software-supported feasibility studies, we make it easy to plan reliable, electrically powered public transport and create the perfect system design for your depot.",
    '#AB1-SystemDesign-eSystem-Klick-Explainer#': "By carrying out a feasibility study, the eMobility Consultants of Daimler Buses check the energy requirements for all existing routes in a simulation model specially tailored to the eCitaro. The state of charge is determined over the course of the day and any necessary charging breaks are suggested. Suitable bus routes will be optimised for electric buses.",
    '#AB2-SystemDesign-eSystem-Klick-Explainer#': "The system design comprises the layout of the depot as a whole, along with the charging infrastructure. This includes the calculation of the required electrical power or energy quantity. In addition, charging concepts are created and charging capacity optimisation is carried out using depot management.",
    '#ZHL-Vorteile-eSysem-Klick-Explainer#': "Your benefits",
    '#BP1-SystemDesign-eSystem-Klick-Explainer#': "Transparency regarding the feasibility of existing daily bus routes with the eCitaro",
    '#BP2-SystemDesign-eSystem-Klick-Explainer#': "Support for route optimisation",
    '#BP3-SystemDesign-eSystem-Klick-Explainer#': "Decision-making aid for optimum battery configuration and charging concept",
    '#BP4-SystemDesign-eSystem-Klick-Explainer#': "Overall concept for converting the depot for electric bus operation",
    '#ZHL1-eSystem-Klick-Explainer#': "Battery storage",
    '#SL1-eSystem-Klick-Explainer#': "Together with Mercedes-Benz Energy, we give used bus batteries a second life.",
    '#AB1-eSystem-Klick-Explainer#': "A stationary energy storage system constructed from used electric bus batteries can significantly extend their life cycle and further increase the sustainability of your electric bus fleet. In combination with an intelligent charging and energy management system, you can also reduce power peaks and thereby reduce electricity costs. Further optimisation potential arises in conjunction with a photovoltaic system that can generate renewable energy during the day, which you can then use to charge the buses at night.",
    '#BP1-eSystem-Klick-Explainer#': "Increased profitability",
    '#BP2-eSystem-Klick-Explainer#': "Peak load reduction",
    '#BP3-eSystem-Klick-Explainer#': "Smaller carbon footprint",
    '#ZHL2-eSystem-Klick-Explainer#': "Transformer",
    '#SL2-eSystem-Klick-Explainer#': "We will advise you on the individual selection of the right transformer for your depot.",
    '#BE1_eSystem-Klick-Explainer#': "Customer depot",
    '#AB2-eSystem-Klick-Explainer#': "In order to be able to supply a fleet of battery-powered buses with energy, the energy from the medium-voltage grid (generally 6 kV – 60 kV) generally needs to be reduced to a voltage level of 400V AC. Depending on the requirements and space available in the vehicle depot, this can be done with a compact transformer or, in the case of large fleets, with a walk-in transformer station.",
    '#BP1-Transformator-eSystem-Klick-Explainer#': "Provision of the required total power depending on the size of the bus fleet and charging concept",
    '#BP2-Transformator-eSystem-Klick-Explainer#': "Planning reliability through coordination of energy supply with planned expansion stages of your bus fleet",
    '#ZHL3-eSystem-Klick-Explainer#': "Construction work and electric installations",
    '#SL3-eSystem-Klick-Explainer#': "We safeguard an efficient and secure energy supply at your depot – from planning to implementation.",
    '#AB3-eSystem-Klick-Explainer#': "Our eMobility consultants will take care of the planning of all necessary construction work of your chargers' power supply, with a central manager taking care of the coordination and implementation.",
    '#BP1-Bauarbeiten-eSystem-Klick-Explainer#': "Implementation with a view to the overall development goal of the depot",
    '#BP2-Bauarbeiten-eSystem-Klick-Explainer#': "Consideration of individual operational processes in construction planning",
    '#BP3-Bauarbeiten-eSystem-Klick-Explainer#': "Construction work can be carried out during operation",
    '#ZHL4-eSystem-Klick-Explainer#': "Charging and depot management",
    '#SL4-eSystem-Klick-Explainer#': "With our charging and depot management solutions, you have an overview of all charging processes at all times and can significantly reduce your electricity costs.",
    '#AB4-eSystem-Klick-Explainer#': "With professional charging management, you can optimise the charging capacity of the entire fleet so that your buses are charged and preconditioned in good time with a controlled total output. Cost-intensive network load peaks can thus be reduced and electricity can be purchased in the most favourable price window. The charging plans are automatically created within the specified load windows and the preconditioning of the vehicles is also controlled centrally in order to ensure the maximum range of the buses. For this purpose, we offer clear charging management solutions for small fleets, but can also optimally control large electric bus fleets with integrated electric depot management solutions and assign routes and parking spaces with pinpoint accuracy.",
    '#BP1-Lademanagement-eSystem-Klick-Explainer#': "Cost reduction thanks to low peak loads",
    '#BP2-Lademanagement-eSystem-Klick-Explainer#': "Automatic charging plans for all buses aligned with departure times and routes",
    '#BP3-Lademanagement-eSystem-Klick-Explainer#': "Central control of preconditioning for maximum range and increased comfort",
    '#BP4-Lademanagement-eSystem-Klick-Explainer#': "Mains-friendly charging in the best price window",
    '#ZHL5-eSystem-Klick-Explainer#': "charging infrastructure",
    '#SL5-eSystem-Klick-Explainer#': "We support you with tailor-made charging infrastructure solutions from well-known European charging system manufacturers.",
    '#AB5-eSystem-Klick-Explainer#': "The optimum operating concept for an electrically driven bus depends heavily on the respective operating conditions. The eMobility consultants of Daimler Buses Solutions consider local conditions to determine which charging infrastructure is best suited: plug-in charging at the depot or a combination of plug-in charging and pantographs along your route. You'll also be given recommendations for the right charging technology based on the deployment profile.",
    '#BP1-Ladeinfrastruktur-eSystem-Klick-Explainer#': "Charging infrastructure and vehicle from a single source",
    '#BP2-Ladeinfrastruktur-eSystem-Klick-Explainer#': "Perfect interplay between charging management, infrastructure and vehicle",
    '#BP3-Ladeinfrastruktur-eSystem-Klick-Explainer#': "Guaranteed compatibility with the eCitaro battery systems – thanks to extensive interoperability tests",
    '#ZHL6-eSystem-Klick-Explainer#': "Spare parts, eServiceContracts and Service Network",
    '#SL6-eSystem-Klick-Explainer#': "With OMNIplus eServiceContracts for electric buses, we offer you a professional service at predictable costs.",
    '#AB6-eSystem-Klick-Explainer#': "You can rely on our proven range of OMNIplus eServiceContracts, which we have geared towards the requirements of electromobility. Configure the optimum service scope to individually suit your electric bus fleet and match your demands. For example, the ePremium eServiceContract covers any maintenance as per manufacturer specifications and any repairs to the high-voltage system. The package also includes other repairs to the entire vehicle including all required spare parts. The largest and densest bus/touring coach service network in Europe – with over 600 authorised service points – is available to you and will professionally supply you with genuine parts. This means that you are always on the road safely, reliably and cost-effectively with your fully electric eCitaro.",
    '#BP1-Ersatzteile-eSystem-Klick-Explainer#': "Two OMNIplus eServiceContracts to choose from: eBasic or ePremium",
    '#BP2-Ersatzteile-eSystem-Klick-Explainer#': "A variant for the high-voltage battery: extended battery warranty",
    '#BP3-Ersatzteile-eSystem-Klick-Explainer#': "Future-proof service offer for electromobility",
    '#BP4-Ersatzteile-eSystem-Klick-Explainer#': "Attractive package prices instead of individual services",
    '#BP5-Ersatzteile-eSystem-Klick-Explainer#': "Service and maintenance contracts also for the charging infrastructure",
    '#ZHL7-eSystem-Klick-Explainer#': "Digital services",
    '#SL7-eSystem-Klick-Explainer#': "We offer you the OMNIplus ON digital service environment for your Mercedes-Benz eCitaro and eCitaro fuel cell.",
    '#AB7-eSystem-Klick-Explainer#': "OMNIplus ON offers you a wide range of digital services – thereby ensuring maximum vehicle availability and cost-effectiveness, such as the new OMNIplus ON Uptime pro with remote diagnosis. Consequently, you can keep an eye on the condition of your vehicles at all times and thus optimise service planning. With OMNIplus ON monitor, you also have the option of monitoring the state of charge and remaining range of your vehicles as well as analysing their energy consumption – for example also for the new Mercedes-Benz eCitaro fuel cell. In addition, virtual OMNIplus ON interfaces enable simple data integration into your own fleet or depot management systems. The new TiGR data package, certified according to the universal ITxPT standard, provides a homogeneous database for the entire fleet and thus simplifies your vehicle analyses. As an aside: all vehicle data is provided in real time and, on request, also in raw format. Data provision is always legally compliant and in line with the new UN Regulation 155 centring on vehicles' cyber security.",
    '#BP1-DigitaleServices-eSystem-Klick-Explainer#': "Maximum vehicle availability",
    '#BP2-DigitaleServices-eSystem-Klick-Explainer#': "Transparency regarding the current range and consumption of eCitaro vehicles",
    '#BP3-DigitaleServices-eSystem-Klick-Explainer#': "Maximum data security",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff#': "hydrogen infrastructure",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_copy_head#': "We support you with tailor-made\ninfrastructure solutions for an operational hydrogen supply.",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_copy#': "The optimum operating concept for an alternatively driven bus depends greatly on the respective operating conditions. Our Mercedes-Benz eCitaro fuel cell buses have a hydrogen (H2) fuel cell on board in addition to the batteries to extend their range. Daimler Buses eMobility experts derive which refuelling infrastructure is best suited from the required quantities of hydrogen and the local conditions. They analyse whether a hydrogen filling station in the vicinity would be suitable or whether it would make more sense to set one up at your own depot. In addition, experts check whether hydrogen can be supplied as part of a trailer solution or with an electrolyser. You will also receive matching recommendations and offers relating to the appropriate charging infrastructure for your electric bus fleet.",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile#': "Advantages for you:",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b1#': "Infrastructure and vehicle from a single source",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b2#': "Perfect interplay between charging management, infrastructure and vehicle",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b3#': "Decision-making tool for selecting the optimal H2 refuelling concept",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b4#': "Collaboration with experienced partners",
} as const;
