// Don't change manually as this is generated automatically!

export const fr_FR = {
    '#Dictionary-eMobilitaets-Oekosystem#': "L'écosystème de mobilité électrique",
    '#HL-eMobilitaetsOekosystem-DB#': "L'écosystème de mobilité électrique de Daimler Buses",
    '#SHL-eSystem-Klick-Explainer#': "La conception du système",
    '#SL-SystemDesign-eSystem-Klick-Explainer#': "Des études de faisabilité assistées par un logiciel nous permettent de planifier de manière fiable le transport public électrique et de créer la conception du système pour votre dépôt.",
    '#AB1-SystemDesign-eSystem-Klick-Explainer#': "Dans le cadre d’une étude de faisabilité, les conseillers en mobilité électrique de Daimler Buses vérifient les besoins énergétiques de toutes les lignes et trajets existants dans un modèle de simulation spécialement adapté à l’eCitaro. L’état de charge est alors déterminé au cours de la journée et les pauses nécessaires à la recharge sont proposées le cas échéant. Les lignes de bus appropriées sont optimisées pour les bus électriques.",
    '#AB2-SystemDesign-eSystem-Klick-Explainer#': "La conception du système comprend la configuration du dépôt dans son ensemble ainsi que celle du système d’alimentation électrique. Cela inclut le calcul de la puissance électrique ou de la quantité d’énergie nécessaire. Des concepts de recharge sont également élaborés et une optimisation de la performance de recharge est effectuée par l’intermédiaire de la gestion du dépôt.",
    '#ZHL-Vorteile-eSysem-Klick-Explainer#': "Vos avantages",
    '#BP1-SystemDesign-eSystem-Klick-Explainer#': "La transparence sur la faisabilité avec l'eCitaro pour des trajets journaliers des lignes de bus existantes",
    '#BP2-SystemDesign-eSystem-Klick-Explainer#': "Le soutien pour l’optimisation des trajets",
    '#BP3-SystemDesign-eSystem-Klick-Explainer#': "L’aide à la décision pour la configuration optimale de la batterie et le concept de recharge",
    '#BP4-SystemDesign-eSystem-Klick-Explainer#': "Un concept global pour la conversion du dépôt à l’exploitation de bus électriques",
    '#ZHL1-eSystem-Klick-Explainer#': "L'accumulateur",
    '#SL1-eSystem-Klick-Explainer#': "Nous donnons une seconde vie aux batteries de bus usagées – en collaboration avec Mercedes-Benz Energy",
    '#AB1-eSystem-Klick-Explainer#': "Un accumulateur d’énergie stationnaire composé de batteries de bus électriques usagées permet de prolonger considérablement leur cycle de vie et d’augmenter encore la durabilité de votre flotte de bus électriques. En combinaison avec un système intelligent de gestion de la charge et de l’énergie, vous pouvez en outre réduire les pics de puissance et ainsi réduire les coûts d’électricité. Un potentiel d’optimisation supplémentaire apparaît en combinaison avec une installation photovoltaïque qui produit l’énergie renouvelable pendant la journée, que vous pouvez ensuite utiliser la nuit pour recharger les bus.",
    '#BP1-eSystem-Klick-Explainer#': "L’amélioration de la rentabilité",
    '#BP2-eSystem-Klick-Explainer#': "La réduction des pics de consommation",
    '#BP3-eSystem-Klick-Explainer#': "La réduction de l’empreinte carbone",
    '#ZHL2-eSystem-Klick-Explainer#': "Transformateur",
    '#SL2-eSystem-Klick-Explainer#': "Nous vous conseillons sur le choix individuel du transformateur adapté à votre dépôt.",
    '#BE1_eSystem-Klick-Explainer#': "Dépôt client",
    '#AB2-eSystem-Klick-Explainer#': "Pour pouvoir alimenter une flotte de bus équipés de batteries, l’énergie du réseau moyenne tension (en général 6 kV - 60 kV) doit être abaissée à un niveau de tension de 400 V AC. En fonction des exigences et de l’espace disponible dans le dépôt de véhicules, cela peut se faire avec un transformateur compact ou avec un poste de transformation accessible dans le cas de grandes flottes.",
    '#BP1-Transformator-eSystem-Klick-Explainer#': "La mise à disposition de la puissance totale nécessaire en fonction de la taille de la flotte de bus et du concept de recharge",
    '#BP2-Transformator-eSystem-Klick-Explainer#': "La sécurité de planification grâce à la coordination du système d’alimentation en énergie avec les étapes d’extension prévues de votre flotte de bus",
    '#ZHL3-eSystem-Klick-Explainer#': "Des travaux de construction et des installations électriques",
    '#SL3-eSystem-Klick-Explainer#': "Nous assurons l’efficacité et la sécurité du système d’alimentation en énergie dans votre dépôt, de la planification à l’exécution.",
    '#AB3-eSystem-Klick-Explainer#': "Les conseillers en mobilité électrique de Daimler Buses se chargent pour vous de la planification de tous les travaux de construction nécessaires à l’alimentation électrique des chargeurs. La coordination et l’exécution sont confiées à une direction de projet centrale.",
    '#BP1-Bauarbeiten-eSystem-Klick-Explainer#': "La mise en œuvre dans le cadre de l’objectif de développement global du dépôt",
    '#BP2-Bauarbeiten-eSystem-Klick-Explainer#': "La prise en compte des processus opérationnels individuels dans la planification de la construction",
    '#BP3-Bauarbeiten-eSystem-Klick-Explainer#': "Les travaux peuvent être réalisés sans interrompre les activités de l’entreprise",
    '#ZHL4-eSystem-Klick-Explainer#': "La gestion de la recharge et du dépôt",
    '#SL4-eSystem-Klick-Explainer#': "Avec nos solutions de gestion de la recharge et du dépôt, vous gardez à tout moment un œil sur tous les processus de recharge et pouvez réduire considérablement vos coûts d’électricité.",
    '#AB4-eSystem-Klick-Explainer#': "Grâce à une gestion professionnelle de la charge, vous pouvez optimiser la puissance de charge de l’ensemble de la flotte, de sorte que vos bus soient chargés et préconditionnés à temps avec une puissance totale contrôlée. Il est ainsi possible de réduire les pics de consommation du réseau coûteux et d’obtenir l’électricité dans la fenêtre de prix la plus avantageuse. Les plans de recharge sont établis automatiquement dans les plages de charge prédéfinies et le préconditionnement des véhicules est également géré de manière centralisée afin de garantir l’autonomie maximale des bus. Pour ce faire, nous proposons des solutions de gestion de la charge claires pour les petites flottes, mais nous pouvons également piloter de grandes flottes d’autobus électriques avec des solutions intégrées de gestion du dépôt électrique et affecter les trajets et les emplacements de manière ciblée.",
    '#BP1-Lademanagement-eSystem-Klick-Explainer#': "La réduction des coûts grâce à de faibles pics de consommation",
    '#BP2-Lademanagement-eSystem-Klick-Explainer#': "Plans de recharge automatiques pour tous les bus en fonction des heures de départ et des trajets",
    '#BP3-Lademanagement-eSystem-Klick-Explainer#': "Une commande centrale du préconditionnement pour une autonomie maximale et un confort accru",
    '#BP4-Lademanagement-eSystem-Klick-Explainer#': "Une recharge ménageant le réseau dans la meilleure fenêtre de prix",
    '#ZHL5-eSystem-Klick-Explainer#': "Le système d’alimentation électrique",
    '#SL5-eSystem-Klick-Explainer#': "Nous vous assistons avec des solutions d'infrastructure de charge sur mesure de fabricants européens de renom de systèmes de recharge.",
    '#AB5-eSystem-Klick-Explainer#': "Le concept d’exploitation optimal pour un bus à entraînement électrique dépend fortement des conditions d’utilisation. Les conseillers en mobilité électrique de Daimler Buses Solutions déduisent les conditions locales pour déterminer le système d’alimentation électrique le mieux adapté : charge par fiche dans le dépôt ou combinaison avec des pantographes sur le trajet. Vous recevez également des recommandations relatives à la technique de recharge adéquate en fonction de votre profil d’utilisation.",
    '#BP1-Ladeinfrastruktur-eSystem-Klick-Explainer#': "Système d’alimentation électrique et véhicule d’une seule source",
    '#BP2-Ladeinfrastruktur-eSystem-Klick-Explainer#': "Une interaction parfaite entre la gestion de la recharge, l’infrastructure et le véhicule",
    '#BP3-Ladeinfrastruktur-eSystem-Klick-Explainer#': "Une compatibilité garantie avec les systèmes de batterie eCitaro – grâce à des tests d’interopérabilité complets",
    '#ZHL6-eSystem-Klick-Explainer#': "Des pièces de rechange, des contrats de service et le réseau de Service",
    '#SL6-eSystem-Klick-Explainer#': "Avec les Contrats eService OMNIplus pour autobus électriques, nous vous proposons un service professionnel à des coûts prévisibles.",
    '#AB6-eSystem-Klick-Explainer#': "Faites confiance à notre offre éprouvée de Contrats eService OMNIplus, que nous avons adaptés aux exigences de la mobilité électrique. Vous pouvez composer le volume de maintenance optimal pour votre flotte de bus électriques de manière individuelle et en fonction de vos exigences. Par exemple, le Contrat eService ePremium couvre tous les entretiens selon les instructions du constructeur et toutes les réparations du réseau de bord haute tension. Le pack comprend également d’autres réparations sur l’ensemble du véhicule, y compris toutes les pièces de rechange nécessaires. Le réseau de service autobus le plus vaste et le plus dense d’Europe, avec plus de 600 points de service agréés, est à votre disposition pour un approvisionnement professionnel en pièces d’origine. Ainsi, vous êtes toujours en sécurité, fiable et rentable sur la route avec votre eCitaro entièrement électrique.",
    '#BP1-Ersatzteile-eSystem-Klick-Explainer#': "Deux Contrats eService OMNIplus au choix : eBasic ou ePremium",
    '#BP2-Ersatzteile-eSystem-Klick-Explainer#': "Une variante pour la batterie haute tension : l’extension de la garantie sur la batterie",
    '#BP3-Ersatzteile-eSystem-Klick-Explainer#': "Un service durable pour l’électromobilité, des prix forfaitaires attrayants au lieu de prestations individuelles, des Contrats Service et de maintenance également pour le système de recharge",
    '#ZHL7-eSystem-Klick-Explainer#': "Services numériques",
    '#SL7-eSystem-Klick-Explainer#': "Nous vous proposons l’univers de services numériques d'OMNIplus ON pour votre eCitaro et eCitaro fuel cell Mercedes-Benz.",
    '#AB7-eSystem-Klick-Explainer#': "OMNIplus ON vous offre une multitude de services numériques pour une disponibilité et une rentabilité maximales des véhicules. Comme par exemple le nouvel OMNIplus ON Uptime pro avec télédiagnostic. Vous avez ainsi un aperçu permanent de l’état de vos véhicules et pouvez ainsi optimiser la planification des services. Avec OMNIplus ON monitor, vous avez également la possibilité de surveiller l’état de charge et l’autonomie restante de vos véhicules ainsi que d’analyser leur consommation d’énergie - par exemple pour le nouvel eCitaro fuel cell Mercedes-Benz. De plus, les interfaces virtuelles d’OMNIplus ON permettent une intégration facile des données dans votre propre système de gestion de flotte ou de dépôt. Ainsi, le nouveau Data Package TiGR, certifié selon la norme uniforme ITxPT, fournit une base de données homogène pour l’ensemble de la flotte et simplifie ainsi vos analyses de véhicules. Au fait : Toutes les données du véhicule sont fournies en temps réel et, sur demande, au format brut. Cela se fait à tout moment en toute sécurité juridique et dans le cadre du nouveau règlement 155 de l’ONU concernant la cybersécurité des véhicules.",
    '#BP1-DigitaleServices-eSystem-Klick-Explainer#': "Une disponibilité maximale des véhicules",
    '#BP2-DigitaleServices-eSystem-Klick-Explainer#': "La transparence sur l’autonomie et la consommation actuelles des véhicules eCitaro",
    '#BP3-DigitaleServices-eSystem-Klick-Explainer#': "Une sécurité maximale pour vos données",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff#': "infrastructure hydrogène",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_copy_head#': "Nous vous soutenons avec des solutions\nd’infrastructure sur mesure pour la mise en place de votre approvisionnement en hydrogène.",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_copy#': "Le concept d’exploitation optimal d’un autobus à propulsion alternative dépend fortement des conditions d’utilisation. Pour prolonger son autonomie, notre eCitaro fuel cell Mercedes-Benz est équipé, en plus des batteries, d’une pile à combustible fonctionnant à l’hydrogène (H2). Les conseillers Daimler Buses eMobility déduisent quelle infrastructure de réservoir est la plus appropriée en fonction des quantités d’hydrogène nécessaires et des conditions locales. Ils analysent s’il est possible d’utiliser une station-service d’hydrogène dans les environs ou s’il est judicieux de l’installer sur son propre dépôt au moyen d’une station-service propre. En outre, on examine si l’approvisionnement en hydrogène peut être mis en œuvre avec une solution de remorque ou un électrolyseur. En outre, vous recevrez des recommandations et des offres adaptées pour le système d’alimentation électrique approprié pour votre flotte de bus électriques.",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile#': "Les avantages pour vous :",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b1#': "L'infrastructure et le véhicule d’un seul tenant",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b2#': "Une interaction parfaite entre la gestion de la charge, l’infrastructure et le véhicule",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b3#': "Aide à la décision pour le choix du concept optimal de ravitaillement en H2",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b4#': "Une collaboration avec des partenaires expérimentés",
} as const;
