import styled, {keyframes} from 'styled-components';
import {DimensionProps} from '../customTypes';
import {getRelativeWidth} from "../../../config";

interface ClickableContainerProps {
    offsetTop: number;
    offsetLeft: number;
}

interface IconProps extends DimensionProps {
    delay: number;
}

const fadeInAnimation = () => keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const fadeOutAnimation = () => keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const iconAnimation = () => keyframes`
  0% {
    transform: scale(.5);
    opacity: 0;
  }

  90% {
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const wifiAnimation = (item: number) => keyframes`
    ${(item === 1) && `
        0% { opacity: 0; }      
        20%, 75% { opacity: 1; }
        100% { opacity: 0; }
    `}
    
    ${(item === 2) && `
        0%, 20% { opacity: 0; }      
        40%, 80% { opacity: 1; }
        100% { opacity: 0;}
    `}
    
    ${(item === 3) && `
        0%, 40% { opacity: 0; }      
        60%, 85% { opacity: 1; }
        100% { opacity: 0; }
    `}
`;

export const LandingpageClickableContainer = styled.div<ClickableContainerProps>`
    display: flex;
    flex-direction: column;
    align-items:center;
    width: 15vw;
    position: absolute;
    top: ${ClickableContainerProps => ClickableContainerProps.offsetTop + '%'};
    left: ${ClickableContainerProps => ClickableContainerProps.offsetLeft + '%'};
    cursor: pointer;
`;

export const LandingpageClickableIcon = styled.div<IconProps>`
    width: ${IconProps => IconProps.width}vW;
    opacity: 0;
    animation: ${iconAnimation} .5s ease-in-out ${IconProps => IconProps.delay}s forwards;
    
    svg {
        #wifi {
            path:nth-child(2),
            path:nth-child(3),
            path:nth-child(4) {
                opacity: 0;
                animation-duration: 3s;
                animation-iteration-count: infinite;                
            }
        
            path:nth-child(2) {
                animation-name: ${wifiAnimation(1)};
            }
            
            path:nth-child(3) {
                animation-name: ${wifiAnimation(2)};
            }
            
           path:nth-child(4) {
                animation-name: ${wifiAnimation(3)};
            }
        }
    }
`;

export const ConnectionWrapper = styled.div<DimensionProps>`
    width: ${DimensionProps => DimensionProps.width}vW;
    position: absolute;
    top: 8.5%;
    left: 47%;
    transform: translateX(-50%);
    opacity: 0;
    animation: ${fadeInAnimation} 1s ease-in-out 2s forwards, ${fadeOutAnimation} 1s ease-in-out 5s forwards;
    
    &:before {
        content: '';
        width: ${getRelativeWidth(2)}vW;
        height: 100vH;
        background: #fff;
        display: block;
        position: absolute;
        left: 53%;
        transform: translateY(-100%);
    }
`;