import {MainContainer} from '../../assets/ts/layout/main';
import {ContentContainer} from '../../assets/ts/layout/content';
import {Pages, Locale} from '../../assets/ts/customTypes';
import {Navigation} from '../components/Navigation';
import {
    HexagonTextWrapper,
    HexagonBackgroundWrapper,
    HexagonImagesWrapper,
    ContentHeadline,
    ContentIntro,
    ContentText,
    ContentIcon
} from '../../assets/ts/pages/infopage';
import {getRelativeWidth} from '../../config';
import HexagonTextBGFull from '../../assets/backgrounds/bg-hexa.svg';
import HexagonTextBGFrame from '../../assets/backgrounds/bg-hexa-frame.svg';
import {ContentInfoBox} from '../components/ContentInfoBox';
import {InfoBox} from '../../assets/ts/customTypes';
import {Icon} from '../components/Icon';
import {HexagonImages} from '../components/HexagonImages';

interface InfopageProps {
    currentPage: Pages;
    updateCurrentPage: (page: Pages) => void;
    locale: Locale;
}

export function Infopage(props: InfopageProps) {
    const {locale, currentPage} = props;

    const Text = () => {
        const content = locale.pages[currentPage];
        if(typeof content === 'undefined') return <></>;

        return(
            <HexagonTextWrapper width={getRelativeWidth(800)}>
                <ContentHeadline>{content.headline}</ContentHeadline>
                {('intro' in content) &&
                    <ContentIntro>{content.intro}</ContentIntro>
                }
                {('text' in content && content.text) &&
                    <ContentText>{content.text}</ContentText>
                }
                {(content.infoBoxes?.length) && content.infoBoxes.map((box: InfoBox, key: number) => (<ContentInfoBox key={key} icon={box.icon} headline={box.headline} list={box.list}/>))}
            </HexagonTextWrapper>
        );
    }

    return (
        <MainContainer name='infopage'>
            <ContentContainer>
                <HexagonBackgroundWrapper width={getRelativeWidth(1154)}>
                    <img src={HexagonTextBGFull} alt='' className='background-full' />
                    <img src={HexagonTextBGFrame} alt='' className='background-frame' />
                    {currentPage !== Pages.SYSTEMDESIGN && (
                        <ContentIcon width={getRelativeWidth(200)}>
                            <Icon page={currentPage} type='content' />
                        </ContentIcon>
                    )}
                    <Text />
                </HexagonBackgroundWrapper>
                <HexagonImagesWrapper width={getRelativeWidth(649)}>
                    <HexagonImages pages={locale.pages} currentPage={currentPage}/>
                </HexagonImagesWrapper>
            </ContentContainer>
            <Navigation {...props}/>
        </MainContainer>
    );
}
